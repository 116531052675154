<template>
  <base-section id="news" class="grey lighten-4">
    <base-section-heading title="Latest Blogs">
      Check out the latest blogs from Alton Carter
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(news, i) in articles"
          :key="i"
          cols="12"
          md="4"
        >
          <news-card
            v-bind="news"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
      NewsCard: () => import('@/components/news/Card'),
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-text',
          date: '',
          category: 'Experience',
          comments: 5,
          title: 'Welcome Colin, Our Second Son',
          text: '',
          slug: 'welcome-colin-our-second-son',
          src: 'https://images.unsplash.com/photo-1511948374796-056e8f289f34?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
        },
        {
          icon: 'mdi-text',
          date: '',
          category: 'Experience',
          comments: 8,
          title: 'Welcome Kelton to the World',
          text: '',
          slug: 'welcome-kelton-to-the-world',
          src: 'https://images.unsplash.com/photo-1470116945706-e6bf5d5a53ca?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8cHJlZ25hbmN5fGVufDB8fDB8&auto=format&fit=crop&w=500&q=60',
        },
        {
          icon: 'mdi-text',
          date: '',
          category: 'Experience',
          comments: 13,
          title: 'I Am Becoming a Father!!!',
          text: '',
          slug: 'i-am-becoming-a-father',
          src: 'https://images.unsplash.com/photo-1527620743113-fac8dd0f8d17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80',
        },
      ],
    }),
  }
</script>

<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
      space="56"
    >
      <base-icon class="mb-8">
        mdi-twitter
      </base-icon>

      <base-section-heading
        color="transparent"
        title="Social Media"
      >
        Keep in touch with me on Twitter or catch up on what I'm doing.
      </base-section-heading>

      <a class="twitter-timeline" href="https://twitter.com/Alton__Carter" data-tweet-limit="1" data-width="399" ></a>

      <div class="py-4" />

      <v-btn
        class="font-weight-bold"
        color="white"
        href="https://twitter.com/Alton__Carter"
        light
        min-width="168"
        target="_blank"
        x-large
      >
        Follow Me

        <v-icon right>
          mdi-open-in-new
        </v-icon>
      </v-btn>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
















import { Component, Vue } from 'vue-property-decorator';

import Hero from '@/components/sections/Hero.vue';
import SocialMedia from '@/components/sections/SocialMedia.vue';
import InfoAlt from '@/components/sections/InfoAlt.vue';
import News from '@/components/sections/News.vue';
import ReadTheBook from '@/components/sections/ReadTheBook.vue';

@Component({
  components: {
    Hero,
    SocialMedia,
    InfoAlt,
    News,
    ReadTheBook,
  },
  metaInfo: () => ({
    title: 'Home'
  })
})
export default class Home extends Vue {
  
}

<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      src="https://bloximages.chicago2.vip.townnews.com/stwnewspress.com/content/tncms/assets/v3/editorial/f/f0/ff099031-60c0-506f-9780-092a70fffcaf/585c8e2a81036.image.jpg?resize=750%2C520"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), hsl(1deg 77% 55% / 41%)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="From a Troubled Childhood to Success" />

          <base-body>
            "...I always believed if I just kept putting one foot in front of the other that one day I would find myself in a normal home with food in the refrigerator, a bed to sleep in, heat in the winter, and a family that was kind to each other." - Alton Carter
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn :to="{name: 'Biography'}">
              Read More
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
            >
              <v-icon left>mdi-mouse</v-icon> Scroll Down
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>

<style lang="sass">
  .v-image__image--cover
    transform: scaleX(-1)
</style>

<template>
  <base-section id="mobile">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <base-section-heading
            align="left"
            subtitle=""
            tag="h2"
            title="Alton Carter"
          >
            Hello, I'm Alton Carter. I was the first person in my family to graduate from high school and college. Throughout my life, I faced many challenges that hindered my progress or reversed it at times. I never thought I was going to succeed until I met Kristin. Kristin was the best person I met, and she helped me throughout my troubles. When the time came, I proposed to Kristin and now we are married. We gave birth to two sons, Kelton and Colin, and are currently living in Oklahoma.
          </base-section-heading>

          <v-row>
            <v-col
              v-for="([icon, title], i) in features"
              :key="i"
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">
                <base-avatar
                  :icon="icon"
                  class="mr-6"
                  color="primary"
                  dark
                  outlined
                />

                <v-responsive max-width="112">
                  <base-subtitle
                    :title="title"
                    class="text--primary"
                  />
                </v-responsive>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <base-img
            src="https://pbs.twimg.com/media/Dr7NLz9UwAAHni6?format=jpg&name=large"
            class="ml-auto"
            contain
            max-width="600"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'ReadTheBook',

    data: () => ({
      features: [
      ],
    }),
  }
</script>
